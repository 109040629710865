import React from 'react'
import Seo from 'components/seo'

const NotFound = () => (
    <main id="main-content">
        <Seo title="404! Error: Page not found." />
        <h1 data-testid="not-found-heading">Oh no!</h1>
        <p>The page was not found.</p>
    </main>
)

export default NotFound